<template>
	<div class="containerHeader flex_items">
		<div class="leftAlign">
			<img class="icon_img" src='@/static/imagesPhone/headerPhone/ego-menu.png' @click="drawer = !drawer"/>
		</div>
		<div class="centerAlign">
			<span v-if="topTitle">{{topTitle}}</span>
			<el-image v-else :src="imageSrcTwo" class="logo_style" @click="$router.push('/')"></el-image>
		</div>
		<div class="rightAlign"></div>
		
		<!-- 菜单 -->
		<el-drawer
			:visible.sync="drawer"
			:direction="direction"
			:with-header="false"
			:modal='false'
			class='drawerStyle'>
			<el-menu :default-active="$route.path" router active-text-color="#D6B98E">
				<el-menu-item index="/">首页</el-menu-item>
				<el-menu-item index="/business">业务领域</el-menu-item>
				<el-menu-item index="/news">资讯中心</el-menu-item>
				<el-menu-item index="/about">关于我们</el-menu-item>
				<el-menu-item index="/contact">联系我们</el-menu-item>
			</el-menu>
		</el-drawer>
	</div>
</template>

<script>
export default {
	props: {
		topTitle: String,
	},
	data() {
		return {
			drawer: false,// 控制菜单是否出现
			direction:'ttb',// 控制菜单从左到右出现
			imageSrcTwo: require('../static/images/logo.jpg'),
		}
	},
	methods: {
		
	},
}
</script>

<style>
.containerHeader{
    border-bottom: 1px solid #e6e6e6;
	width: 100%;
	height: 50px;
	/* line-height: 60px; */
	background-color: white;
	z-index: 1000;
}
.leftAlign .icon_img{
    width: 25px;
}
.leftAlign {
  flex: 1;
  text-align: right;
}
.centerAlign {
  flex: 7;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}
.rightAlign {
  flex: 1;
}
.drawerStyle{
    margin-top:50px;
    height: 950px;
	/* width: 50%; */
}
.logo_style {
	width: 102px;
	height: 26px;
}
</style>