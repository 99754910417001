<template>
	<div style="padding: 50px 20px;">
		<div class="title">广东设立新型储能产业天使投资基金</div>
		<div class="data">2023-10-28</div>
		<el-image :src="image" style="width: 100%;"></el-image>

		<!-- 内容 -->
		<div>
			<div class="smallTitle">广东省推动新型储能产业高质量发展的指导意见</div>

			<div class="text">
				新型储能产业市场广阔、发展潜力巨大，对促进经济社会发展全面绿色转型具有重要意义。为抢占新型储能产业制高点和产业发展前沿，将新型储能产业打造成为我省“制造业当家”的战略性支柱产业，根据《国家发展改革委
				国家能源局关于加快推动新型储能发展的指导意见》《广东省培育新能源战略性新兴产业集群行动计划（2021—2025年）》等政策，结合我省实际，提出以下意见。
			</div>

			<div class="smallTitle">一、总体要求</div>
			<div class="text">
				（一）指导思想。坚持以习近平新时代中国特色社会主义思想为指导，全面贯彻落实党的二十大精神，完整、准确、全面贯彻新发展理念，服务和融入新发展格局，以碳达峰碳中和为目标，抢抓新型储能产业发展的战略机遇期，坚持市场主导与政府引导相结合，坚持研发创新与推广应用相结合，坚持合作引进与重点培育相结合，坚持创新机制与安全保障相结合，推动创新链、产业链、资金链、人才链深度融合，着力构建技术、市场、政策驱动良好局面，将广东打造成为具有全球竞争力的新型储能产业创新高地，为服务我国能源发展战略作出广东努力、广东贡献。
			</div>
			<div class="text">
				（二）发展目标。新型储能产业链关键材料、核心技术和装备自主可控水平大幅提升，全产业链竞争优势进一步凸显，市场机制、标准体系和管理体制更加健全，大型骨干企业规模实力不断壮大，产业创新力和综合竞争力大幅提升。到2025年，全省新型储能产业营业收入达到6000亿元，年均增长50%以上，装机规模达到300万千瓦。到2027年，全省新型储能产业营业收入达到1万亿元，装机规模达到400万千瓦。
			</div>

			<div class="smallTitle">二、加大新型储能关键技术和装备研发力度</div>
			<div class="text">
				（三）提升锂离子电池技术。支持开发超长寿命、高安全性、全气候储能锂离子电池，提升锂电池容量极限，推进新体系锂电池的研发和应用，从材料、单体、系统等多维度提升电池全生命周期安全性和经济性。提升新型锰基正极材料性能，提高硅碳、锂复合负极材料应用水平。加强电解液用高纯溶剂、新型锂盐和功能性添加剂的开发。攻关隔膜关键工艺和设备，研发固态电解质隔膜。加强智能检测评估、柔性无损快速拆解、有价元素绿色高效提取等回收利用关键核心技术攻关。突破搅拌、涂覆、卷绕、分切、回收利用等高效设备。（省科技厅、工业和信息化厅负责）
			</div>
			<div class="text">
				（四）攻关钠离子电池技术。加强钠盐以及水系钠离子电池等技术攻关，提升能量密度，解决寿命短、放电快等技术问题。加强匹配钠基电池的层状氧化物、普鲁士白/普鲁士蓝、聚阴离子等正极材料，煤基碳材料等负极材料，以及电解液、隔膜、集流体等主材和相关辅材的研究，开发关键材料制备工艺和电芯制造装备，降低量产成本，支撑供应链体系建设。（省科技厅负责）
			</div>
			<div class="text">
				（五）融合能源电子技术。加强能源电子信息技术及产品研发，扩大光伏发电系统、新型储能系统、新能源微电网等智能化多样化产品及服务供给。重点研发智能传感器、电池管理芯片、功率半导体器件、直流变换器、能源路由器、断路器、柔性配电装备等关键设备，促进“光储端信”深度融合和创新应用。（省科技厅、工业和信息化厅、发展改革委负责）
			</div>
			<div class="text">
				（六）突破全过程安全技术。突破电池本征安全与控制技术，攻关电池智能传感、热失控阻隔技术。建立储能系统安全分级评估技术体系，提升电芯、电池包、电池簇以及系统级分级安全管理优化技术，开发储能系统安全态势智能评估、安全预警及清洁高效灭火技术。研究多元新型储能接入电网系统控制保护与安全防御技术。突破储能电池循环寿命快速检测和老化状态评价技术，研发退役电池健康评估、分选、修复等梯次利用相关技术。（省科技厅负责）
			</div>
			<div class="text">
				（七）创新智慧调控技术。开发高安全、高效率储能系统集成技术，攻关系统热管理、充放电管理、智能运维、应急响应等控制技术，提升储能系统可用容量及系统可用度。研究“源-网-荷”各侧储能集群建模、分区分类、智能协同控制关键技术，加强需求侧响应、虚拟电厂、云储能、市场化交易等领域关键技术攻关。（省科技厅，广东电网公司负责）
			</div>
			<div class="text">
				（八）发展氢储能等技术。开发质子交换膜电解水、阴离子膜碱性电解水、高温固体氧化物电解水等可再生能源制氢关键技术，提升高压储氢材料与工艺水平，发展低温液态储氢、有机溶液储氢、固体合金储氢等储运技术，研制相关制备、转换、储运设备，提高氢储能的安全性和经济性。持续攻关燃料电池关键技术，加速催化剂、质子交换膜、气体扩散层、双极板、膜电极等核心材料和器件的国产替代。（省科技厅、发展改革委、工业和信息化厅负责）
			</div>
			<div class="text">
				（九）开展储能前瞻技术研究。研发新型环保、长寿命、低成本铅炭电池，开发高导电专用多孔炭材料。开发新型空气电池、固态电池、镁（锌）离子电池等新型电池技术。开展长时储能关键技术攻关和设备研制。发展低成本、高能量密度、安全环保的液流电池，提升液流电池能量效率和系统可靠性，降低全周期使用成本。鼓励开发规模储能用水系新电池。突破氨氢转化技术，推动电催化、生物催化、光催化、电磁催化等技术研发及示范。加强催化剂等甲醇合成关键技术研发，探索直接甲醇燃料电池技术。推动超级电容器、飞轮储能、压缩空气、储冷储热等其他新型储能技术装备研发。（省科技厅负责）
			</div>

			<div class="smallTitle">三、推动新型储能产业壮大规模提升实力</div>
			<div class="text">
				（十）优化锂电池产业区域布局。以广州、深圳、惠州、东莞为重点建设珠江口东岸储能电池产业集聚区，以肇庆、江门、珠海、中山为重点建设珠江口西岸储能电池产业集聚区；以佛山、韶关、江门、清远为重点建设正极材料集聚区；以深圳、惠州、东莞、湛江为重点建设锂电硅碳负极材料集聚区；以珠海、佛山、肇庆、东莞为重点建设锂电隔膜生产制造基地；以惠州、珠海为重点建设电解液专业园区；以梅州、韶关、惠州为重点建设铜箔、铝箔、聚偏二氟乙烯膜等辅助材料集聚区；以广州、深圳、惠州、东莞、肇庆为重点建设储能控制产品及系统集成、先进装备制造集聚区；以珠三角为核心，韶关、汕头及深汕特别合作区为支点建设电池回收利用产业集聚区。结合资源禀赋、技术优势、产业基础、人力资源等条件，支持符合条件的地市建设国家锂电高新技术产业化基地。推动瑞庆时代、比亚迪、中创新航、亿纬锂能、欣旺达、鹏辉能源、璞泰来、贝特瑞等重点项目加快建设，积极引进国内外锂电龙头企业投资项目。（省发展改革委、工业和信息化厅、科技厅，相关地级以上市政府负责）
			</div>
			<div class="text">
				（十一）加快发展钠离子储能电池产业。加速布局钠离子电池领域，推动金属层状氧化物等正极材料、硬碳负极材料、钠盐电解液及铝箔集流体等核心材料及电芯中试化生产，推进适应钠盐电池体系的先进工艺和制造设备研制和应用。支持钠离子电池在储能、电动两轮车、微型电动车、5G基站等领域示范应用，在韶关数据中心集群推动钠离子电池储能项目合作。（省发展改革委、科技厅、工业和信息化厅，韶关市政府负责）
			</div>
			<div class="text">
				（十二）提升储能控制芯片及产品供给能力。推动新型储能与新一代信息技术深度融合，发展储能变流器、电池管理系统、能量管理系统等关键部件，提升电力电子器件、传感器等产品供给能力。加快建设储能控制芯片重大制造项目，大力发展新型储能用高性能、低损耗、高可靠的绝缘栅双极型晶体管功率器件及模块，推动碳化硅、氮化镓等宽禁带半导体功率器件产业化推广及应用。发展小型化、低功耗、集成化、高灵敏度的敏感元件，实现集成多维度信息采集能力的高端传感器研发产业化。（省发展改革委、工业和信息化厅负责）
			</div>
			<div class="text">
				（十三）培育发展电解水制氢设备产业。结合电解水制氢技术路线发展阶段和应用特点，按照多元布局、梯次发展、引育并重的原则，支持省内碱性电解水制氢装备制造龙头企业提升产能规模，加快推进质子交换膜电解水制氢装置重点项目建设，支持阴离子膜碱性、高温固体氧化物电解水制氢创新性企业装备研制和中试生产。（省发展改革委、工业和信息化厅、科技厅，相关地级以上市政府负责）
			</div>
			<div class="text">
				（十四）前瞻布局多元化储能领域。开展液流电池领域离子传导膜、电堆、电解液工程化研制和项目试点。围绕压缩机、膨胀机、换热系统等压缩空气储能产业链关键环节，引导传统能源设备制造商建设压缩空气储能试点项目。推进质子交换膜、膜电极等材料产业化，推动绿氢、绿色甲醇等清洁燃料在重载交通运输和船舶领域示范应用。推进兆瓦级全磁悬浮飞轮储能装置在轨道交通制动能量回收试点应用。（省工业和信息化厅、发展改革委、交通运输厅、能源局，相关地级以上市政府负责）
			</div>
			<div class="text">
				（十五）梯度培育新型储能企业。鼓励新型储能产业链上下游企业强强联合，大力提升产业链整合能力，打造链主企业引领、单项冠军企业攻坚、专精特新企业铸基的世界一流新型储能企业群。遴选新型储能产业链“链主”企业，完善“省长”直通车的长效服务工作机制，在产业促进、产业空间、工业投资、技术改造、技术创新、金融服务、土地和人才保障、承担国家重大专项等领域，依规予以重点支持。实施“个转企、小升规、规改股、股上市”市场主体培育计划，培育一批专精特新“小巨人”、制造业“单项冠军”、“独角兽”等优质新型储能企业。（省工业和信息化厅、科技厅、发展改革委负责）
			</div>
			<div class="text">
				（十六）加大优质企业招商引资力度。实施招商引资“一把手”工程，制定内外资招商一体化政策，围绕新型储能产业链编制招商图谱和路线图，运用专利导航决策机制建立产业链招商数据库，细化招商目标企业清单，强化以商招商、以链招商、以侨招商、靶向招商，积极引进一批新型储能高水平创新型企业、服务机构和产业辐射带动能力强的重大产业项目。利用好广交会、进博会、高交会、投洽会及粤港澳大湾区全球招商大会等重大经贸活动平台，举办新型储能经贸论坛和产业招商活动，吸引优质新型储能项目在粤落地。（省商务厅、工业和信息化厅，相关地级以上市政府负责）
			</div>

			<div class="smallTitle">四、创新开展新型储能多场景应用</div>
			<div class="text">
				（十七）积极开拓海外储能市场。鼓励新型储能企业参与“一带一路”倡议，打造国家级新型储能产品进出口物流中心。鼓励新型储能企业组建联合体积极参与国外大型光储一体化、独立储能电站、构网型储能项目建设。顺应欧洲、北美、东南亚、非洲等市场用能需求，提升“家用光伏+储能”、便携式储能产品设计兼容性和经济性，持续扩大国际市场贸易份额。积极拓展储能产品在海外数据中心备用、特种车辆移动储能等细分领域市场。支持龙头企业带动产业链上下游企业组团出海，推动广东储能产品、技术、标准、品牌和服务走出去，形成“广东总部+海外基地+全球网络”的经营格局。（省商务厅，相关地级以上市政府负责）
			</div>
			<div class="text">
				（十八）拓展“新能源+储能”应用。支持新型储能企业积极参与西藏、新疆、内蒙古、甘肃、青海等新能源高渗透率地区电源侧新型储能电站建设，支撑高比例可再生能源基地外送。探索在阳江、湛江、肇庆、韶关、梅州等新能源装机规模较大、电力外送困难的地区，按一定比例建设“新能源+储能”示范项目，促进新能源高效消纳利用。支持新型储能“众筹共建、集群共享”商业模式，建设共享储能交易平台和运营监控系统。（省能源局，广东电网公司，阳江、湛江、肇庆、韶关、梅州等地级以上市政府负责）
			</div>
			<div class="text">
				（十九）推进定制化应用场景。在输电走廊资源和变电站站址资源紧张地区，支持电网侧新型储能建设。在电网调节支撑能力不足的关键节点和偏远地区、海岛等供电能力不足的电网末端合理配置电网侧储能，提高电网供电保障能力。推动煤电合理配置新型储能，开展抽汽蓄能示范，提升运行特性和整体效益。利用退役火电机组既有厂址和输变电设施建设新型储能，发挥系统保底和支撑作用。探索利用新型储能配合核电调峰调频，提升系统灵活性调节能力。在安全可靠前提下，推动工商业企业和产业园区配置用户侧新型储能，支持精密制造、公用事业、金融证券、通信服务等行业和公共数据中心等供电可靠性和电能质量要求高的重要电力用户配置新型储能。推动源网荷储一体化试点示范，促进储能多元化协同发展。（省能源局、发展改革委、工业和信息化厅，广东电网公司负责）
			</div>
			<div class="text">
				（二十）推进虚拟电厂建设。结合工业园区、大数据中心、5G基站、充电站等区域分布式储能建设，推动建设智能控制终端和能量管理系统，充分挖掘储能、充电桩、光伏、基站等分布式资源调节潜力，实现终端设备用能在线监测、智能互动。在广州、深圳等地开展虚拟电厂建设试点，促进源网荷储高效互动。建设省级虚拟电厂聚合、交易和协同控制平台，形成百万千瓦级虚拟电厂响应能力。（省能源局、工业和信息化厅、发展改革委、通信管理局，广东电网公司，相关地级以上市政府负责）
			</div>
			<div class="text">
				（二十一）鼓励充换电模式创新。在广州、深圳等电动汽车推广领先地区，智能化改造升级直流公共快充站，在公交、城市物流、社会停车场等领域试点建设直流双向充电桩，探索规模化车网互动模式。支持车企在出租车、网约车、物流车等领域，研发生产标准化换电车型，有序推进“光储充换检”综合性充换电站建设，提供一体化换电储能应用解决方案。（省发展改革委、工业和信息化厅、能源局，广东电网公司负责）
			</div>
			<div class="text">
				（二十二）探索氢储能等试点应用。加强与国内外风光可再生能源资源富集地区和国家级化工生产基地合作，引导氢储能产业链企业参与绿氢及绿色甲醇生产基地以及甲醇、液氢、液氨、有机溶液等储运基地建设，推动新型储氢技术与传统煤化工、石油化工产业链耦合发展。探索利用汕头、阳江海上风电资源，江门、肇庆等风光资源以及城市生活垃圾等生物质资源生产绿氢，在石油化工园区、港口码头、城市轨道交通等推动氢储能等试点应用。（省能源局、发展改革委、交通运输厅，汕头、江门、阳江、肇庆市政府负责）
			</div>
			<div class="text">
				（二十三）探索区域能源综合服务模式。在公共建筑集聚区、大型工业园区等建立区域综合能源服务机制，提升智慧能源协同服务水平。统一规划建设和运营区域集中供冷试点项目，实现电、冷、气等多种能源协同互济，探索高密度开发地区的低碳生态城区建设模式。（省住房城乡建设厅、能源局、工业和信息化厅，相关地级以上市政府负责）
			</div>

			<div class="smallTitle">五、提升新型储能产业质量安全管理水平</div>
			<div class="text">
				（二十四）加强全过程安全管控。完善储能设备制造、建设、回收利用等全过程安全管理体系。新型储能电站项目单位要落实企业安全和消防安全主体责任，规范执行储能电站建筑设计防火等标准要求，加强安全风险管控，按相关规定建立企业专职消防队伍，编制应急预案并定期进行事故演练。各地要落实属地管理责任，严格履行项目安全管理程序，规范项目运营维护。（省工业和信息化厅、住房城乡建设厅、应急管理厅、市场监管局、能源局、消防救援总队，各地级以上市政府负责）
			</div>
			<div class="text">
				（二十五）制定安全技术标准。研究制定覆盖规划设计、设备选型、项目准入、质量监督、消防验收、并网验收、运行维护、应急管理、梯次利用、回收利用等全过程安全标准。围绕设备制造、安装、运营等重点领域，建立完善产业安全技术标准，发布一批符合市场创新需求的团体标准。完善储能产品质量监督体系，对储能设备的核心部件实行严格管理。加强储能电站专业技术监督，提高设备安全可靠性。（省工业和信息化厅、住房城乡建设厅、应急管理厅、市场监管局、能源局、消防救援总队，广东电网公司负责）
			</div>
			<div class="text">
				（二十六）建立信息化监管平台。建立覆盖全省新型储能电站数据监管平台，实现对项目运行情况的监测、评估和监管，支撑新型储能电站管理和效益评估。分批分期推动新型储能电站数据监管平台与公安、消防、交通等平台对接，逐步拓展平台服务功能。（省能源局、公安厅、交通运输厅、应急管理厅、消防救援总队，国家能源局南方监管局，广东电网公司负责）
			</div>

			<div class="smallTitle">六、优化新型储能产业发展政策环境</div>
			<div class="text">
				（二十七）加大科技研发支持力度。完善关键核心技术攻关“揭榜挂帅”制度，实施以需求为牵引、以能够解决问题为评价标准的机制，支持有条件的领军企业联合行业上下游、产学研力量，组建体系化、任务型的创新联合体。组织编制《广东省新型储能技术发展路线图》，将新型储能领域技术创新项目列入省重点领域研发计划重点支持方向。省财政对国家级、省级新型储能领域公共服务平台和创新平台建设给予支持；对符合条件的首台（套）重大技术装备推广应用项目按一定比例给予事后奖励。（省科技厅、工业和信息化厅、财政厅负责）
			</div>
			<div class="text">
				（二十八）构建国际市场服务支撑体系。为境外投资、工程建设、项目融资、人员往来、投资权益保护提供全方位的集成服务保障。依托广州港、深圳港等开拓新兴市场班轮航线，在码头危险品堆位、配套危险品拖车引进开发、船公司舱位等方面优先给予进出口支持，提升储能产品运输通关便利化水平。鼓励企业开展产品碳足迹核算，使用绿电等清洁能源，提高企业绿色贸易能力和水平。对参加“粤贸全球”线上线下重点经贸展会的企业，省对参展经费给予适当支持。（省商务厅、交通运输厅、地方金融监管局、发展改革委、公安厅、外办、能源局，海关总署广东分署负责）
			</div>
			<div class="text">
				（二十九）完善新型储能电力市场体系和价格机制。明确源网荷各侧储能市场主体定位，完善市场准入标准和投资备案管理程序，建立健全新型储能参与电能量、辅助服务市场交易机制，探索储能“一体多用、分时复用”市场交易机制和运营模式，加快推动电力辅助服务成本向用户侧合理疏导。健全市场化需求侧响应补偿机制，引导用户通过配置储能优化负荷调节性能。支持虚拟电厂参与需求侧响应和辅助服务市场，逐步扩大虚拟电厂交易品种。持续完善储能调度运行机制，科学优先调用储能电站项目。推动建立电网侧独立储能电站容量电价机制，探索将电网替代型储能设施成本收益纳入输配电价回收。独立储能电站向电网送电的，其相应充电电量不承担输配电价和政府性基金及附加。根据电力供需实际情况动态调整峰谷电价，合理设置电力中长期市场、现货市场上下限价格，为用户侧储能发展创造空间。（省能源局、国家能源局南方监管局、省发展改革委，广东电网公司负责）
			</div>
			<div class="text">
				（三十）加大财政金融支持。设立广东省新型储能产业天使投资基金，重点投向种子期、初创期、成长期科技型企业，建立早、中期科创和重大产业项目让利机制。用好政策性开发性金融工具、制造业中长期贷款等，支持新型储能项目建设。加快广州期货交易所电力和碳排放权期货品种研究和上市。探索推动金融机构建立储能设备厂商白名单及分级制度，推广优质安全产品。鼓励发展供应链金融，推动融资租赁支持储能项目设备采购。引导保险机构设立新型储能专属保险，开发产品质量责任险、产品质量保证险等险种。（省地方金融监管局、发展改革委、工业和信息化厅、科技厅、财政厅，人民银行广州分行、广东银保监局、广东证监局、人民银行深圳市中心支行、深圳银保监局、深圳证监局、广州期货交易所负责）
			</div>

			<div class="smallTitle">七、保障措施</div>
			<div class="text">
				（三十一）加强组织领导。充分发挥制造强省建设领导小组作用，强化部门协作和上下联动，协调解决产业发展中的重大事项和重点工作，形成工作合力。成立广东省新型储能产业发展专家咨询委员会，为产业发展的重大问题、政策措施开展调查研究，进行评估论证，提供咨询建议。充分发挥新型储能产业科技联盟、行业协会和服务机构的协调作用，打造支撑产业发展的高水平科技服务平台和高端智库。（省发展改革委、工业和信息化厅，各相关部门，各地级以上市政府负责）
			</div>
			<div class="text">
				（三十二）加强人才保障。加强新型储能产业人才开发路线图研究，省相关高层次人才引进计划将新型储能产业列入重点支持方向，加快从全球靶向引进高端领军人才、创新团队和管理团队，加大储能领域战略科技人才、科技领军人才培育力度。强化储能技术、储能材料、储能管理等学科专业建设，支持省内有关高校围绕产业需求新增储能技术相关博士点3—5个，硕士点7—10个，扩大相关学科专业本硕博培养规模。开展新型储能产教融合试点，加强省级储能技术产教融合校外实践基地建设。落实国家有关税收优惠政策，鼓励各市在户籍、住房保障、医疗保障、子女就学、创新创业等方面对新型储能产业人才给予优先支持。（省委人才办，省教育厅、科技厅、发展改革委、工业和信息化厅，各地级以上市政府负责）
			</div>
			<div class="text">
				（三十三）支持重大项目建设。新型储能产业重大建设项目优先列入省重点建设项目计划，对符合条件的项目新增建设用地、能耗指标由省统筹安排；建设项目污染物排放总量指标原则上由所在地市解决，地市确实无法解决的再由省层面统筹协调。坚持“三年工程瞄准两年干”，发挥并联审批机制作用，全力以赴抓新型储能产业项目，努力形成更多实物工作量。落实省加快先进制造业项目投资建设政策措施，对符合条件的新型储能产业项目按照新增实际固定资产投资额对所在地政府予以奖励。省财政对新型储能产业链重点产业化项目给予支持，对符合条件的新型储能技术改造项目按规定给予事后奖励。（省发展改革委、工业和信息化厅、财政厅、自然资源厅、生态环境厅、商务厅、能源局，各地级以上市政府负责）
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				image: require('@/static/images/news/new2.png'),
			}
		},
		mounted() {

		},
		methods: {

		},
		created() {

		},
	}
</script>

<style scoped>
	.title {
		text-align: center;
		font-size: 20px;
		color: #4B4B4B;
		font-weight: bold;
	}

	.data {
		color: #B3B3B3;
		text-align: center;
	}

	.smallTitle {
		margin: 30px 0;
		font-size: 18px;
		color: #4B4B4B;
		font-weight: bold;
	}

	.text {
		line-height: 30px;
		color: #4B4B4B;
		font-size: 16px;
		margin: 0 0 20px;
		/* text-indent: 2em; */
		text-align: justify;
		text-justify: auto;
	}
</style>
