<template>
	<div>
		<HeaderPhone topTitle="资讯中心" style="position: fixed;"></HeaderPhone>
		<div style="height: 50px;"></div>
		<div class="banner"></div>
		<div>
			<articleOne v-if="id == 1"></articleOne>
			<articleTwo v-else-if="id == 2"></articleTwo>
			<articleThree v-else-if="id == 3"></articleThree>
			<articleFour v-else-if="id == 4"></articleFour>
		</div>
		<FooterPhone></FooterPhone>
	</div>
</template>

<script>
import HeaderPhone from "@/components/headerPhone";
import FooterPhone from "@/components/footerPhone";
import articleOne from "@/viewsPhone/news/articleOne";
import articleTwo from "@/viewsPhone/news/articleTwo";
import articleThree from "@/viewsPhone/news/articleThree";
import articleFour from "@/viewsPhone/news/articleFour";
export default {
	data() {
		return {
			id: this.$route.query.id,
		}
	},
	components: {
		HeaderPhone,
		FooterPhone,
		articleOne,
		articleTwo,
		articleThree,
		articleFour,
	},
	methods: {
		
	},
}
</script>

<style scoped>
.banner {
	height: 38vh;
	background: url("../static/imagesPhone/news/Mask group.jpg") no-repeat;
	background-size: cover;
	background-position: center center;
}
</style>