<template>
	<div style="padding: 50px 20px;">
		<div class="title">《广东省推进能源高质量发展实施方案（2023-2025年）》</div>
		<div class="data">2023-11-03</div>
		<el-image :src="image" class="imgAdaptive"></el-image>

		<!-- 内容 -->
		<div>
			<div class="smallTitle">广东省推进能源高质量发展实施方案</div>
			<div class="text">
				高质量发展是全面建设社会主义现代化国家的首要任务。能源是经济社会发展的基础和动力，能源高质量发展是经济社会高质量发展的必然要求，是中国式现代化建设的有力支撑。为贯彻落实省委、省政府关于高质量发展的工作部署，全力推动我省能源高质量发展，特制定本实施方案。
			</div>

			<div class="smallTitle">一、总体要求</div>
			<div class="text">
				<div>（一）指导思想。</div>
				<div class="text_next">
					以习近平新时代中国特色社会主义思想为指导，全面贯彻党的二十大精神，深入贯彻习近平总书记关于能源工作的重要指示和批示精神，立足新发展阶段，完整、准确、全面贯彻新发展理念，认真践行“四个革命、一个合作”能源安全新战略，以能源安全保障为首要任务，以能源绿色低碳为发展方向，以能源改革创新为根本动力，推动能源发展质量变革、效率变革、动力变革，加快建设新型能源体系，实现能源更加安全、更加绿色、更加高效、更高质量的发展，为经济社会持续健康高质量发展提供坚实的能源保障。
				</div>
			</div>
			<div class="text">
				<div>（二）基本原则。</div>
				<div class="text_next">
					坚持统筹协调、绿色发展。把握国内外发展形势，加强顶层设计，认真落实碳达峰碳中和目标要求，坚持先立后破，通盘谋划，处理好近期和长远、发展和减排的关系，加快优化调整能源结构，推动发展方式绿色转型，坚定不移走生态优先、绿色低碳的高质量发展道路。
				</div>
				<div class="text_next">
					坚持多元互补、智慧高效。推动多能源品种协调发展，加强多渠道、多主体能源供给，构建多元化能源保障体系，保障能源供应安全。推进“多能互补”和“源网荷储一体化”建设，推动能源与互联网、人工智能、区块链等先进信息技术深度融合，构建智慧能源系统，促进能源系统高效运行。
				</div>；
				<div class="text_next">
					坚持创新引领、改革赋能。坚持把创新作为引领能源高质量发展的第一动力，持续加大能源科技创新投入力度，努力破解制约能源发展的关键技术，打造高端能源创新平台，推动能源科技自立自强。促进新技术新模式新业态发展应用，加快重点领域和关键环节改革步伐，提高能源资源配置效率。
				</div>
				<div class="text_next">
					坚持市场主导、政府推动。充分发挥市场在资源配置中的决定性作用，深化能源市场化改革，推进能源体制机制创新，构建公平开放、竞争有序的能源市场体系。更好发挥政府作用，在规划引领、政策扶持、市场监管等方面加强引导，营造良好发展环境。
				</div>
			</div>
			<div class="text">
				<div>（三）主要目标。</div>
				<div class="text_next">
					到2025年，能源高质量发展取得重大进展，能源安全保障能力大幅提升，能源绿色低碳转型成效显著，能源发展新模式新业态竞相迸发，关键核心技术自主创新实现突破，重点领域、关键环节改革取得实质性进展，协同配置资源能力有序顺畅，能源发展更有效率、更加公平、更可持续，更好地满足人民群众美好生活用能需要。多元安全的能源供应体系进一步完善，省内电力装机容量达到2.6亿千瓦，其中非化石能源发电装机占比达到44%左右。非化石能源成为能源消费增量的主体，消费比重达到29%左右。电气化水平全国领先，电能占终端能源消费比重提升至40%左右。
				</div>
			</div>
			<div style="text-align: center">
				<el-image :src="picture1" class="imgAdaptive"></el-image>
			</div>

			<div class="smallTitle">二、全面构建安全高效清洁低碳的能源供应体系</div>
			<div class="text">
				<div>（一）全力确保能源供应安全。</div>
				<div class="text_next">
					<div>1.加强油气供应保障。</div>
					<div class="text_next">
						加大南海油气资源勘探开发力度，加快推进中海油乌石17-2、中海油恩平等油气田勘探开发，争取实现油气资源增储上产。到2025年，原油产量超过1800万吨/年、天然气产量达到100亿立方米/年左右。推进LNG接收站及配套码头、管道等设施建设。强化天然气战略合作，鼓励稳妥有序在国际市场签订长期采购协议，完善市场化调节机制，拓展多元化采购渠道，保障海外进口稳定供应。组织上游供气企业与用户全面签订供气合同，通过合同落实全年及供暖季资源，确保民生用气实现合同全覆盖。（省能源局、各市能源主管部门，有关油气企业）
					</div>
				</div>
				<div class="text_next">
					<div>2.做好煤炭供应保障。</div>
					<div class="text_next">
						加强与国内煤炭主产省区衔接合作，提升年度国内电煤中长期合同签约量，规范合同签约履约行为，督促供需双方按时按量履行中长期合同，加强运力统筹协调，保障煤炭长期稳定供应。落实国家煤炭进口政策，拓宽煤炭进口渠道，促进各关区进口煤通关便利化，全力保障我省发电进口用煤需求。完善广东省电煤实时监测预警系统，督促省内煤电企业落实国家要求的煤炭最低库存制度。（省能源局、各市能源主管部门，有关煤炭储运企业、有关电厂）
					</div>
				</div>
				<div class="text_next">
					<div>3.提升电力运行调节能力。</div>
					<div class="text_next">
						持续优化电力运行调度。完善电力供需监测预警联动机制，强化发电运行和燃料供应监测。加强发输电设备运行维护管理和一次燃料供应保障，确保统调机组稳定可靠运行，挖掘地方机组、自备电厂顶峰发电潜力。常态化加强机组非计划减出力监测预警，确保枯汛交替、迎峰度夏、迎峰度冬等关键时期骨干机组尽发满发。优化系统运行方式，加强调度智能化建设，推动建设适应新能源发展的新型智慧化调度运行体系。（省能源局、各市能源主管部门，广东电网公司、深圳供电局）
					</div>
					<div class="text_next">
						提升需求侧响应能力。加快新型电力负荷管理系统建设和应用，储备一批高效、快捷、可控的负荷调节能力，实现负荷精准控制和用户常态化、精细化用能管理。健全电力需求响应市场化机制，提升需求响应能力，支持用户侧储能、电动汽车充换电基础设施、分布式发电等用户侧可调节资源，以及负荷聚合商、虚拟电厂运营商、综合能源服务商等参与市场化需求响应，打造实时可观、可测、可控的需求响应系统平台，力争2025年我省市场化需求响应削峰能力达到最高负荷的5%。（省能源局，广东电网公司、深圳供电局、广东电力交易中心）
					</div>
				</div>
				<div class="text_next">
					<div>4.加强能源储备能力建设。</div>
					<div class="text_next">
						积极推进煤炭储运能力建设。建立以港口公共煤炭储运和电厂等大型用煤企业自有储备相结合的煤炭储备体系。建成珠海港高栏港区国家能源煤炭储运中心扩建工程；新建、扩建湛江港、阳江港等码头煤炭泊位，完善煤炭接收与中转储备梯级系统。鼓励支持省内骨干燃煤电厂通过改造现有储煤设施、扩建储煤场地等措施，进一步提高存煤能力。力争到2025年全省煤炭储备能力达到3300万吨，按照国家要求持续提升政府可调度煤炭储备能力。（省能源局、各市能源主管部门，有关煤炭储运企业、有关电厂）
					</div>
					<div class="text_next">
						提升储气能力。建立健全地方政府、供气企业、管输企业、城镇燃气企业各负其责的多层次天然气储气调峰和应急体系。统筹地下储气库、液化天然气（LNG）接收站等储气设施规划布局建设，形成以沿海LNG储罐为主，可替代能源和其他调节手段为补充，管网互联互通为支撑的多层次储气调峰系统。2023年及以后，各县级以上政府形成不低于保障本行政区域5天日均消费量的储备能力，城镇燃气企业形成不低于其年用气量5%的储备能力。（省能源局、有关市能源主管部门，有关天然气企业）
					</div>
					<div class="text_next">
						完善石油储备体系。稳步建设湛江、茂名、揭阳三大原油商业储备基地，加快形成政府储备、企业社会责任储备和生产经营库存有机结合、互为补充，实物储备、产能储备和其他储备相结合的石油储备体系。（省能源局、有关市能源主管部门，有关石油企业）
					</div>
				</div>
				<div class="text_next">
					<div>5.完善能源输送网络。</div>
					<div class="text_next">
						加强电网建设。稳步推进全省电网目标网架建设，坚持“合理分区、柔性互联、安全可控、开放互济”的原则，逐步构建以粤港澳大湾区500千伏外环网为支撑、珠三角内部东西之间柔性直流互联的主网架格局。优化升级区域网架，加强网源协同，统筹优化海上风电、核电、煤电、气电等电源基地送出通道。加快配电基础设施和新能源微电网建设、改造和智能化升级，提升与分布式能源、电动汽车和微电网的接入互动与柔性控制能力，满足高质量和个性化用电需求。大力实施农网巩固提升工程、乡村电气化工程，提升农村电网供电可靠性和电压合格率，强化乡村电力基础设施和公共服务布局，助力建设宜居宜业和美乡村。（省能源局、有关市能源主管部门，广东电网公司、深圳供电局）
					</div>
					<div class="text_next">
						加强油气管道建设。以完善管网布局和加强衔接互联为重点，加快油气管网基础设施规划建设，构建布局合理、覆盖广泛、外通内畅、安全高效的油气管网体系，提高互济保供能力。优化原油、成品油管道布局，提高成品油管输比例，加强成品油跨区调配。加强油气基础设施信息化智能化建设，进一步提升管道系统管理水平。大力推进天然气管网建设和整合，加快形成“全省一张网”。（省能源局、有关市能源主管部门，有关油气企业）
					</div>
				</div>
			</div>
			<div style="text-align: center">
				<el-image :src="picture2" class="imgAdaptive"></el-image>
			</div>

			<div class="text">
				<div>（二）构建清洁低碳的新型能源体系。</div>
				<div class="text_next">
					<div>1.大力发展可再生能源。</div>
					<div class="text_next">
						规模化开发海上风电。推动海上风能资源集中连片开发利用，打造广东海上风电基地；加快推动已纳入规划的海上风电场址建设；有序推进国管海域场址项目试点示范，组织做好新项目业主竞争性配置和国管海域项目示范开发等工作。（有关市能源主管部门，广东电网公司、有关可再生能源开发企业）
					</div>
					<div class="text_next">
						积极发展光伏发电。坚持集中式和分布式并举，大力发展分布式光伏，因地制宜建设集中式光伏电站项目。在公共建筑、产业园区、基础设施、农业农村等重点领域积极推广光伏发电应用。（有关市能源主管部门，广东电网公司、有关可再生能源开发企业）
					</div>
					<div class="text_next">
						因地制宜发展陆上风电和生物质能。结合资源条件，在远离居民生活区且风能资源较为丰富的区域适度开发集中式陆上风电，因地制宜发展分散式陆上风电。因地制宜开发生物质能，统筹规划垃圾焚烧发电、农林生物质发电、生物天然气项目开发。（有关市能源主管部门，广东电网公司、有关可再生能源开发企业）
					</div>
					<div class="text_next">
						推动新能源发电项目能并尽并。统筹新能源发电、配套储能，送出工程同步规划、同步建设、同步投运。到2025年，全省可再生能源发电装机规模达到7900万千瓦。（有关市能源主管部门，广东电网公司、有关可再生能源开发企业）
					</div>
				</div>
				<div class="text_next">
					<div>2.积极安全有序发展核电。</div>
					<div class="text_next">
						在确保安全的前提下，有序推进惠州太平岭核电一期、陆丰核电5、6号机组和廉江核电一期建设，全力推动惠州核电3、4号机组、陆丰核电1、2号机组项目尽快获国家核准，积极推动后续核电项目开展前期工作。（有关市能源主管部门，中广核集团，国家电投集团，中核集团）
					</div>
				</div>
				<div class="text_next">
					<div>3. 加快布局建设抽水蓄能电站。</div>
					<div class="text_next">
						积极推进梅州二期、云浮水源山、汕尾陆河、肇庆浪江等抽水蓄能电站项目建设。重点布局建设对系统安全保障作用强、对新能源规模化发展促进作用大、经济指标相对优越的抽水蓄能电站，因地制宜开展中小抽水蓄能电站建设。到2025年，抽水蓄能发电装机规模达到968万千瓦，开工建设规模达到980万千瓦以上。（有关市能源主管部门，南方电网、省能源集团、深圳能源集团等）
					</div>
				</div>
				<div class="text_next">
					<div>4.促进新型储能发展。</div>
					<div class="text_next">
						积极拓展新型储能多元化应用场景，推进“新能源+储能”建设，规划引导独立储能合理布局，鼓励用户侧储能发展。完善新型储能参与电力市场机制和电网调度运行机制，建立健全储能标准规范，提升储能建设质量和运营安全水平。积极推动新型储能技术创新，促进新型储能与大数据中心、5G基站、数字电网等新型基础设施融合应用，支持各地开展源网荷储一体化、多能互补、虚拟电厂等试点、示范。到2025年，储能装机容量达到300万千瓦。（各市能源主管部门，有关储能企业）
					</div>
				</div>
				<div class="text_next">
					<div>5.加强托底保障电源建设。</div>
					<div class="text_next">
						推进灵活性气电建设。综合考虑调峰需求、建设条件、天然气资源保障情况，在负荷中心合理规划布局建设调峰气电。引导新建气电机组配置FCB功能，具备孤网运行能力和黑启动功能，提高抗灾保障能力；积极规划建设汕尾、湛江、茂名市抗灾保障电源。在工业园区、产业聚集区等热负荷需求高的地区，按照“以热定电”原则合理布局天然气热电联产及分布式能源站项目，为区域实施集中供热、供电、供冷，实现能源高效循环利用。到2025年，气电规模达到约5500万千瓦。（省能源局、各市能源主管部门，广东电网公司、有关发电企业）
					</div>
					<div class="text_next">
						合理建设先进支撑煤电。逐步推进煤电从基础性电源向基础保障性和系统调节性电源并重转型。立足我国以煤为主的资源禀赋，“十四五”期间，按照“先立后改、超超临界、不选新址”的原则，推动新增煤电项目规划建设。对服役期满的煤电机组，结合电力热力供需形势和机组状况，按照延寿运行、“关而不拆”转为应急备用和关停拆除等方式分类处置。推进现有煤电机组节能降耗、供热改造和灵活性改造，推动燃煤自备电厂广泛参与电力调峰，推动煤电机组CCUS技术发展及全流程系统集成和示范应用。（省能源局、各市能源主管部门，广东电网公司、有关发电企业）
					</div>
				</div>
			</div>
			<div style="text-align: center">
				<el-image :src="picture3" class="imgAdaptive"></el-image>
			</div>

			<div class="smallTitle">三、全面构建科学节约的能源消费体系</div>
			<div class="text">
				<div>（一）加强高质量发展用能保障。</div>
				<div class="text_next">
					完善能源消耗总量和强度调控，重点控制化石能源消费，原料用能和可再生能源消费不纳入能源消耗总量和强度控制，为高质量发展腾出用能空间，为增强产业链供应链韧性强化支撑。有序实施重大项目能耗单列，保障国家重大项目合理用能需求。聚焦十大战略性支柱产业和十大战略性新兴产业集群，推动能耗要素向产业带动强、技术水平先进、绿色低碳的先进制造业项目倾斜。严把增量“两高”项目关口，鼓励与推动“两高”项目通过“上大压小”“减量替代”“搬迁升级”等方式进行产能整合，坚决遏制高耗能、高排放、低水平项目盲目发展。（省能源局、各市节能主管部门）
				</div>
			</div>
			<div class="text">
				<div>（二）大力推动重点领域节能降碳。</div>
				<div class="text_next">
					对标能效先进水平，深入推进煤电、钢铁、有色、建材、石化、化工等行业节能降碳升级改造。打造节能低碳交通运输体系，调整优化运输结构，大力推动交通领域电动化，推广节能和新能源车船。提升建筑节能标准，推行绿色建造工艺和绿色建材，加强超低能耗和近零能耗建筑示范。推动公共机构能耗定额管理，全面开展节约型公共机构示范单位创建。提升数据中心、5G等新型基础设施能效水平，加快绿色数据中心建设。（省能源局、各市节能主管部门，有关企业）
				</div>
			</div>
			<div class="text">
				<div>（三）加快终端用能电气化。</div>
				<div class="text_next">
					在交通、建筑、工商业和民生等领域因地制宜、稳步有序推进经济性好、节能减排效益佳的电能替代，逐步扩大电能替代范围。加快新能源汽车推广应用，大力推进电动汽车充电基础设施建设，加快建设适度超前、科学布局、安全高效的充电网络体系。到2025年，全省规划累计建成集中式充电站4500座以上、公共充电桩25万个以上。积极开展车网互动示范，在广州、深圳、佛山、东莞、珠海建设车网互动充电站V2G示范工程。全面推广建设港口岸电设施，推动电动船舶试点应用。到2025年电力占终端能源消费比重达到40%以上。（省能源局、各市能源主管部门，广东电网公司、深圳供电局、广东电力交易中心）
				</div>
			</div>

			<div class="smallTitle">四、全面构建能源科技创新和产业发展体系</div>
			<div class="text">
				<div>（一）完善能源科技创新体系。</div>
				<div class="text_next">
					加强创新能力和创新平台建设。围绕节能降碳、新能源、数字化智能化改造等技术研发应用，加强科研机构、高等院校、企业等产学研合作，建设一批国际一流的能源科技创新平台。推进先进能源科学与技术广东省实验室及分中心建设，支持国际知名企业在广东设立研发中心，鼓励新能源龙头企业与国外领军企业合作开展技术研究。引进和培养高层次人才和创新团队，提升能源科技创新及产业竞争力。（省能源局，各市能源主管部门）
				</div>
				<div class="text_next">
					着力突破关键核心技术。瞄准能源科技和产业变革前沿，加快新能源开发、天然气水合物、新型电力系统等方面的技术研发，提升优势领域持续创新能力。从源网荷储各环节挖掘技术创新潜力，大力推广应用一批关键技术与重大装备，支撑新能源快速发展，推动新型电力系统建设。鼓励各市出台专项支持政策，加大核能、燃机、海上风电、光伏发电、氢能、智能电网、碳捕获利用与封存等领域核心装备研制支持力度，依托重点工程推动能源技术装备攻关和产业链融合发展。加强产学研用融合，鼓励开展技术和应用场景等分类示范、首台（套）重大技术装备示范，提升核心技术装备自主可控水平。推动数字技术融入能源产、运、储、销、用各环节，构筑能源系统各环节数字化、智能化创新应用体系，带动能源系统全要素生产率提高。（省能源局，各市能源主管部门，有关企业）
				</div>
			</div>
			<div class="text">
				<div>（二）打造万亿新能源产业集群。</div>
				<div class="text_next">
					做大做强优势产业。做大做强海上风电装备制造业，加快形成集整机制造与叶片、电机、齿轮箱、轴承等关键零部件制造，以及大型钢结构、海底电缆等加工生产为一体的高端装备制造基地，提前布局海上风电运维基地。不断壮大太阳能产业，支持光伏设备、逆变器、封装、浆料等企业做大做强，重点支持高效低成本晶硅太阳能电池生产和关键设备制造，推动钙钛矿及叠层电池、柔性薄膜电池、光伏组件回收利用等先进技术研发；补强电池片等核心设备短板，提升高效电池片制造设备自主生产能力，做强光伏产业链。推进先进核能产业布局，依托惠州加速器驱动嬗变研究装置和强流重离子加速器装置、先进能源科学与技术广东省实验室、江门中微子实验站等先进科研平台，持续推进先进核能产业发展。发挥产业集聚效应，推动核技术应用产业链上下游向广东集聚，以中广核集团为龙头，构建核能研发、设计、建设、管理和运营服务完整产业链，推动深圳核电研发总部、惠州非核试验研究基地、阳江组件试验基地建设，完善广州南沙、深圳龙岗核电产业基地。依托东莞松山湖北区燃气分布式能源、中山板芙镇燃气分布式能源等项目，推动国产H-25系列燃气轮机首台套示范应用，依托东方电气南沙基地建设燃机运维中心。（省能源局、各市能源主管部门，有关企业）
				</div>
				<div class="text_next">
					培育壮大新兴产业。大力发展储能产业，建设省新型储能制造业创新中心，推进产学研用协同示范基地和规模化实证基地建设。加强新型储能电池产业化技术攻关，优化锂电池制造、关键材料、系统集成及装备制造、电池回收利用产业区域布局，提升全产业链集聚化程度，支持钠离子储能电池技术研发和产业化。创新储能项目营运模式，强化储能标准体系建设。推动压缩空气、飞轮储能等其他新型储能技术的应用突破。聚焦氢能核心技术研发和先进设备制造，加快培育从氢气制备、储运、燃料电池电堆、关键零部件和动力系统集成的全产业链。多渠道增加氢气供给能力，适度超前建设氢气储运加基础设施，利用低温氢燃料电池产业先发优势，形成广州-深圳-佛山-东莞环大湾区核心区车用燃料电池产业集群。适应柔性直流输电技术进步和电网数字化转型发展需要，推进小微传感器、大功率电子器件等核心设备国产化，大力发展电力专用芯片、智能传感、通信与物联、智能终端、电力大数据、智能输变配工程集成等产业，壮大智能电网产业链。（省能源局，各市能源主管部门，有关企业）
				</div>
			</div>
			<div style="text-align: center">
				<el-image :src="picture4" class="imgAdaptive"></el-image>
			</div>

			<div class="smallTitle">五、全面构建现代化的能源治理体系</div>
			<div class="text">
				<div>（一）健全能源法规、标准体系。</div>
				<div class="text_next">
					加强能源高质量发展法治建设，修订完善能源领域地方法律法规，健全适应能源高质量发展需要的能源法律规章体系，推进《广东省成品油流通管理条例》《广东省大气污染防治条例》制修订，增强法律法规的针对性和有效性。研究和制修订清洁高效火电、可再生能源发电、核电、储能、氢能、清洁能源供热、新型电力系统等领域技术标准和安全标准，提升能源标准化水平，推动太阳能发电、风电等领域标准国际化，支持行业协会、龙头企业牵头研提地方标准、行业标准和企业标准。（省能源局、各市能源主管部门）
				</div>
			</div>
			<div class="text">
				<div>（二）深入推进能源体制改革。</div>
				<div class="text_next">
					坚持能源市场化改革方向，全面形成“放开两头、管住中间”的能源运行管理体制机制，完善能源价格形成机制。进一步深化电力市场化改革，全面构建“中长期+现货+辅助服务”电力市场体系，积极建设完善电力现货市场。建立统一、多层次电力市场体系，推进南方区域电力市场建设，实现不同层次电力市场高效协同、有机衔接。完善有利于可再生能源优先利用的市场交易机制，促进绿色电力交易，支持微电网、分布式电源、储能和负荷聚合商等新兴市场主体独立参与电力市场。推动天然气市场化改革，完善天然气价格形成机制，加强油气管网公平开放监管，持续推进天然气基础设施向第三方开放。（省能源局，广东电网公司、深圳供电局、广东电力交易中心，有关油气企业）
				</div>
			</div>
			<div class="text">
				<div>（三）深化“放管服”改革。</div>
				<div class="text_next">
					持续推动能源领域简政放权，继续委托或下放行政许可事项。进一步优化能源领域营商环境，对标对表国际先进持续提升“获得电力”服务水平，进一步缩短企业办电时间，降低企业用能成本。破除制约市场竞争的各类障碍和隐性壁垒，落实市场准入负面清单制度，支持各类市场主体依法平等进入负面清单以外的能源领域。优化清洁低碳能源项目核准、备案流程，简化分布式能源投资项目管理程序。（省能源局、各市能源主管部门，广东电网公司、深圳供电局、广东电力交易中心）
				</div>
			</div>
			<div class="text">
				<div>（四）完善能源安全监管体系。</div>
				<div class="text_next">
					建立健全能源行业数据统计体系，健全能源安全监测预警体系，强化能源行业运行监测分析。制定极端情形下的能源应急预案，增强重特大自然灾害、突发事件应急处置能力。建立健全能源供应安全风险辨识管控制度，完善隐患排查治理制度。加强石油天然气长输管道风险点危险源和高后果区管控，做好油气管道安全保护。加强应急演练，提高快速响应能力。（省能源局、各市能源主管部门，有关能源企业）
				</div>
			</div>


			<div class="smallTitle">六、全面构建互利共赢的能源合作体系</div>
			<div class="text">
				<div>（一）积极谋划外电接续送电。</div>
				<div class="text_next">
					积极协调外来电力保供增供，确保西电东送落实送电协议计划，稳定西电东送能力。谋划中远期外来清洁电力供应，加快推动藏东南至粤港澳大湾区输电工程核准开工，争取藏东南澜沧江上游清洁能源基地送电南方电网第I、II回输电工程纳入国家规划并推动实施，积极开展青海清洁能源基地送电广东前期工作，支持开展新疆、内蒙清洁能源基地送电广东研究论证。支持与周边省区电网互联互通，提升应急互保能力。（省能源局、有关市能源主管部门，南方电网公司、广东电网公司，有关能源企业）
				</div>
			</div>
			<div class="text">
				<div>（二）支持煤炭与煤电联营、煤电与新能源联营。</div>
				<div class="text_next">
					按照“市场为主、企业自愿”的原则，促进煤炭与煤电、煤电与新能源企业合作联营。鼓励支持我省电力企业与外省煤炭企业开展联营，通过项目合资、单向或交叉持股等方式实现优势互补，增强电煤供应保障能力。支持煤电与新能源互补开发，统一送出、统一调度，提高送出通道利用率，提升新能源消纳能力，建立兼顾煤电灵活性与新能源低碳化的联营模式。（省能源局、有关市能源主管部门，南方电网公司、广东电网公司，有关能源企业）
				</div>
			</div>
			<div class="text">
				<div>（三）深化区域能源合作。</div>
				<div class="text_next">
					加强与能源资源富集国家和地区合作，积极引进煤炭、石油、天然气等能源资源。引进一批国际知名专业贸易商，做大做强能源进口、转口贸易，形成定点、定量稳定供应模式。支持省内能源企业“引进来”与“走出去”相结合，消纳吸收能源领域国际先进技术，努力突破关键、核心技术“卡脖子”问题。支持在“一带一路”沿线重点国家地区积极布局，开展双边多边合作，拓展能源市场。（省能源局、有关市能源主管部门，南方电网公司、广东电网公司，有关能源企业）
				</div>
			</div>

			<div class="smallTitle">七、保障措施</div>
			<div class="text">
				<div>（一）加强组织实施。</div>
				<div class="text_next">
					依托省能源重大项目建设指挥部、省保障电力供应协调领导小组、省煤电油气运协调机制等统筹组织实施能源高质量发展各项工作。各地市要建立健全能源高质量发展工作协调机制，明确职责分工，细化工作方案，精心组织实施，加强省市、部门、政企协同联动，及时协调解决突出问题，确保各项任务措施落实落细落地。
				</div>
			</div>
			<div class="text">
				<div>（二）加大政策支持。</div>
				<div class="text_next">
					完善能源高质量发展相关支持政策，推进能源安全保障、能源绿色低碳转型、能源重大项目建设、能源体制机制改革等重点任务实施，推动完善能源价格政策，健全能源市场机制，落实可再生能源消纳责任权重政策，推动绿电市场交易和认证政策落地，落实金融支持，加强用地、用林、用海、能耗、环评等要素保障。
				</div>
			</div>
			<div class="text">
				<div>（三）强化项目建设。</div>
				<div class="text_next">
					优化投资结构，加强项目储备，有序推动重大项目建设，形成“储备一批、开工一批、建设一批、投产一批”的循环持续发展态势，力争更多项目纳入国家规划和省重点项目计划。坚持资源跟着项目走的原则，加大对重大项目的要素保障，加强对重大项目建设全流程监督管理和指导服务。
				</div>
			</div>
			<div class="text">
				<div>（四）严格监督评估。</div>
				<div class="text_next">
					按照实施方案确定的发展目标、主要任务和职责分工，建立信息反馈和评估机制，定期调度落实进展情况。结合年度工作进展对能源高质量发展情况进行总结评估，适时组织开展督查评估，充分发挥专家和社会评价对推动能源高质量发展的引导作用。
				</div>
			</div>
			<div class="text">来源：广东省能源局</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				image: require('@/static/images/news/new3.png'),
				picture1: require('@/static/images/news/four1.png'),
				picture2: require('@/static/images/news/four2.png'),
				picture3: require('@/static/images/news/four3.png'),
				picture4: require('@/static/images/news/four4.png'),
			}
		},
		mounted() {

		},
		methods: {

		},
		created() {

		},
	}
</script>

<style scoped>
	.title {
		text-align: center;
		font-size: 20px;
		color: #4B4B4B;
		font-weight: bold;
	}

	.data {
		color: #B3B3B3;
		text-align: center;
		margin: 15px 0 20px;
	}

	.smallTitle {
		margin: 30px 0;
		font-size: 18px;
		color: #4B4B4B;
		font-weight: bold;
	}

	.text {
		line-height: 30px;
		color: #4B4B4B;
		font-size: 16px;
		margin: 0 0 20px;
		/* text-indent: 2em; */
		text-align: justify;
		text-justify: auto;
	}

	.text_next {
		margin: 10px 0;
		text-indent: 2em;
		/* 设置段落的缩进，2em表示两个字符的宽度 */
	}
</style>
