<template>
    <div class="footer-content flex_two_center" style="flex-direction: column;">
        <div class="vue-flex">
			<div style="margin-right: 30px;">
				<img :src="require('../static/images/footer/logo.png')" style="width: 89px;height: 89px;"/>
			</div>
			<div>
				<img :src="require('../static/images/footer/code.jpg')" style="width: 70px;height: 70px;" />
				<div>微信公众号</div>
			</div>
		</div>
		<div class="lineStyle"></div>
        <div style="text-align: center;">
			<div>Copyright  © 2023 广东网宇科技股份有限公司 版权所有</div>
			<div style="margin: 5px 0;">All Rights Reserved.  </div>
			<div @click="ICPBtn">ICP备：粤ICP备14014412号</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
			
        }
    },
    methods: {
		ICPBtn() {
			window.open('https://beian.miit.gov.cn/', '_blank');
		},
	},
}
</script>

<style>
.lineStyle {
	background-color: #28456c;
	height: 1px;
	width: 90%;
	margin: 20px;
}
.footer-content {
	color: #527CBC;
	height: 270px;
	background-color: #1D2F4A;
}
</style>